.user_overlay label {
    display: block;
    width: 70%;
    margin-bottom: 10px;
    text-align: left;
    margin-inline: auto;
}

.user_overlay input {
    width: calc(70% - 8px);
    height: 30px;
    margin-bottom: 20px;
    font-family: var(--fontMain);
    font-size: 16px;

}

.user_overlay input:last-of-type {
    margin-bottom: 80px;
}

.user_error__message {
    width: calc(70% - 15px - 15px);
    margin-bottom: 20px;
    padding: 15px;
    font-size: 13px;

    outline: 1px solid #C72E2E;
    background-color: #FFF7F7;
    margin-inline: auto;
}
